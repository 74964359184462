<template>
    <div class="container">
        <div class="headerPart maxWidth">
            <router-link :to="{ name: 'Home' }"
                         class="whiteBox">
                Close
            </router-link>
            <router-link :to="nextLink"
                         class="whiteBox center">
                Next project
            </router-link>
        </div>

        <div v-if="$store.state.currentProject.loaded"
             class="timeMachineContainer">
            <TimeMachine :images="pImages"
                         :navigatable="true"
                         :aspect="aspect"
                         class="timeMachineRef"
                         sizes="100vw">
                <div class="projectText">
                    <div class="projectDescription"
                         v-html="$store.state.currentProject.description" />
                </div>
            </TimeMachine>


        </div>
        <div v-else
             id="loading">
            Loading
        </div>
    </div>
</template>
<script>
import Image from '../components/Image.vue'
import TimeMachine from '../components/TimeMachine.vue'
export default {
    components: { Image, TimeMachine },
    data() {
        return {

        }
    },
    mounted() {
        if (document.querySelector("#pageData")) {
            this.$store.commit("setCurrent", {
                ...JSON.parse(document.querySelector("#pageData").innerHTML),
                loaded: true
            })
            document.querySelector("#pageData").parentNode.removeChild(document.querySelector("#pageData"))
            this.fixLinks()
        }

        else {

            this.$store.dispatch("getCurrentProject", this.$route.params.project).then(r => {
                if (r) {
                    this.$store.commit("unsetCurrentProject")
                    this.$store.commit("setCurrent", {
                        ...r,
                        loaded: false
                    })
                    document.querySelector("title").innerHTML = this.$store.state.currentProject.seo.title
                }
            });
            var q = {
                "query": "page('projects/" + this.$route.params.project + "')",
                "select": {
                    "title": true,
                    "seo": true,
                    "slug": true,
                    "description": "page.description.kt",
                    "year": true,
                    "portrait": true,
                    "images": {
                        "query": "page.project_images.toFiles",
                        "select": {
                            "imagedata": true
                        }
                    },
                    "thumb": "page.seo_image.toFile.imagedata"
                }
            };
            this.$query(q).then(r => {
                // this.project = r.data.result;
                // this.loaded = true
                this.$store.commit("setCurrent", {
                    ...r.data.result,
                    loaded: true
                })

                document.querySelector("title").innerHTML = this.$store.state.currentProject.seo.title
                this.fixLinks()

                // console.log(r.data.result)
            })
        }



    },
    computed: {
        pImages() {
            return this.$store.state.currentProject.images.map(image => {
                return image.imagedata
            })
        },
        nextLink() {
            return {
                name: 'Project',
                params: {
                    project: this.nextSlug
                }
            }
        },
        nextSlug() {
            var p = this.$store.state.projects.find(p => {
                return p.slug == this.$store.state.currentProject.slug
            })
            if (p) {
                return this.$store.state.projects[(this.$store.state.projects.indexOf(p) + 1) % this.$store.state.projects.length].slug
            }
            else return "na"
        },
        aspect() {
            return this.$store.state.currentProject.portrait == "true" ? 1.2 : 0.75
        },
    },
    methods: {
        fixLinks() {
            this.$nextTick(() => {
                document.querySelector(".projectDescription").querySelectorAll("a").forEach(a => {
                    // console.log(a)
                    a.setAttribute("target", "_blank")
                })
            })
        }
    }
}
</script>

<style lang="scss">
.projectText {

    p {
        margin: 0px;
        padding: 0px;
    }
}

.projectText {
    // color: white;
    padding-top: 5px;
}

.projectDescription {
    text-align: center;
}

.projectYear {
    text-align: right;
    padding-right: 8px;
}
</style>
<style lang="scss" scoped>
.container {
    position: relative;
    overflow: auto;
    width: 100%;
    height: 100%;
}

.headerPart {
    max-width: 100%;
    margin: 0px auto;
    text-align: left;
    top: 29px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);

    z-index: 100;

    a {
        text-decoration: none;
    }

    .center {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
    }
}


.timeMachineRef {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.timeMachineContainer {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    padding: 51px 30px;
    box-sizing: border-box;
    overflow: hidden;

}


@media screen and (max-width: (4*$minGap + 3*$imageWidth)) {
    .headerPart {
        position: fixed;
        top: unset;
        bottom: 30px;
        text-align: center;
        left: 50%;
        transform: translateX(-50%);
        z-index: 100;

        .center {
            position: relative;
            left: unset;
            transform: unset;
            margin-left: 30px;
        }
    }

    .timeMachineRef {
        position: relative;
        top: unset;
        left: unset;
        transform: unset;
        margin: 50px auto 100px auto;
    }
}


.timeMachine {

    max-height: calc(100% - 100px);

}

@media screen and (max-width: 600px) {
    .timeMachineContainer {

        padding: 30px 30px;
        position: static;
        top: unset;
        left: unset;
        transform: unset;
        height: unset;
    }

    .timeMachine {
        max-height: calc(100% - 40px);
    }

    .headerPart {
        // bottom: 10px;
    }
}

@media screen and (max-height: 500px) {
    .timeMachineContainer {

        height: 500px;
        top: 0px;
        transform: translateX(-50%);
    }
}


#loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
</style>